<template>
  <Wrapper>
    <div class="development">
      <!-- banner -->
      <DetailBanner title="关于发展历程"></DetailBanner>
      <!-- 发展理念 -->
      <div class="FazhanLinian">
        <div class="w">
          <div class="image">
            <img :src="backgroundUrl" />
          </div>
          <div class="text">
            <div class="li" v-for="item in list" :key="item.title">
              <span class="title">{{item.title}} - </span>
              <span>{{item.desc}}</span>
            </div>
          </div>
        </div>

      </div>
      <!-- 发展历程 -->
      <div class="FazhanLicheng">
        <WaterTitle Chinese="发展历程" English="DEVELOPMENT HISTORY"></WaterTitle>
        <div class="w">
          <img :src="hisImg" />
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from './sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import Wrapper from '../components/Wrapper.vue'

export default {
  mixins: [],
  components: { Wrapper, DetailBanner, WaterTitle },
  data() {
    return {
      backgroundUrl: '',
      hisImg: '',
      list: []
    }
  },
  created() {
    this.scrollTop()
    this.getHisory()
  },
  methods: {
    scrollTop() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop)
      if (scrollTop === 0) {
        return false
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    async getHisory() {
      const res = await this.$axios.getDevelopmentHistoryPage({
        offset: 1,
        limit: 1,
        statusCd: 0
      })
      this.backgroundUrl = res.records[0].backgroundUrl
      this.hisImg = res.records[0].historyUrl
      this.list = JSON.parse(res.records[0].allDescription)
    }
  }
}
</script>

<style lang='scss' scoped>
.FazhanLinian {
  padding: 50px 0;
  font-size: 18px;
  color: #515a6e;
  .w {
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: 700;
    color: #181f2d;
  }
  .text {
    padding-left: 44px;
  }
  .li {
    margin-bottom: 20px;
  }
}
.FazhanLicheng {
  padding: 50px 0;
  text-align: center;
  .w {
    position: relative;
    margin-top: 50px;
    .look-more {
      color: #0e4db5;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
